"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setSamlDomain = exports.setProtocol = exports.setOidcDomain = exports.setHostname = exports.isPublicHelpCenterUrl = exports.isPublicHelpCenterDomain = exports.getSamlDomain = exports.getPublicHelpCenterUrl = exports.getOidcDomain = exports.getHostname = exports.default = void 0;
var _module2 = require("../../utils/module");
var _stateModule = require("../../utils/stateModule");
var _urls = require("../../utils/urls");
var initialState = {
  hostname: '',
  protocol: 'https:'
};
var namespace = (0, _module2.namespaceType)('/redux/locationExtra');
var _module = (0, _stateModule.createStateModule)({
  initialState: initialState,
  typeNamespace: namespace(''),
  moduleRootPath: 'locationExtra'
});
var _default = exports.default = _module.reducer;
var setHostname = exports.setHostname = function setHostname(hostname) {
  return _module.actionCreators.updateState({
    hostname: hostname
  });
};
var setProtocol = exports.setProtocol = function setProtocol(protocol) {
  return _module.actionCreators.updateState({
    protocol: protocol
  });
};
var getHostname = exports.getHostname = function getHostname(state) {
  return _module.getLocalState(state).hostname;
};
var setSamlDomain = exports.setSamlDomain = function setSamlDomain(domain) {
  return _module.actionCreators.updateState({
    samlDomain: domain
  });
};
var getSamlDomain = exports.getSamlDomain = function getSamlDomain(state) {
  return _module.getLocalState(state).samlDomain || '';
};
var setOidcDomain = exports.setOidcDomain = function setOidcDomain(domain) {
  return _module.actionCreators.updateState({
    oidcDomain: domain
  });
};
var getOidcDomain = exports.getOidcDomain = function getOidcDomain(state) {
  return _module.getLocalState(state).oidcDomain || '';
};
var isPublicHelpCenterDomain = exports.isPublicHelpCenterDomain = function isPublicHelpCenterDomain(hostname) {
  return (hostname || '').startsWith('public.');
};
var getPublicHelpCenterUrl = exports.getPublicHelpCenterUrl = function getPublicHelpCenterUrl(state) {
  var PHCHostname = isPublicHelpCenterDomain(state.locationExtra.hostname) ? state.locationExtra.hostname : 'public.' + state.locationExtra.hostname.split('.').filter(function (a) {
    return a !== 'wwww';
  }).join('.');
  return state.locationExtra.protocol + '//' + PHCHostname;
};
var isPublicHelpCenterUrl = exports.isPublicHelpCenterUrl = function isPublicHelpCenterUrl(url) {
  if (!(0, _urls.isExternalUrl)(url)) return false;
  var urlObj = new URL(url);
  return urlObj.hostname && isPublicHelpCenterDomain(urlObj.hostname);
};