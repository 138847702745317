"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = clientMiddleware;
var _locationExtra = require("../modules/locationExtra");
var urls = _interopRequireWildcard(require("../../utils/urls"));
var _excluded = ["promise", "types"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
function clientMiddleware(clients) {
  return function (_ref) {
    var dispatch = _ref.dispatch,
      getState = _ref.getState;
    return function (next) {
      return function (action) {
        if (typeof action === 'function') {
          return action(dispatch, getState);
        }
        var promise = action.promise,
          types = action.types,
          rest = _objectWithoutProperties(action, _excluded); // eslint-disable-line no-redeclare
        if (!promise || !types) {
          return next(action);
        }
        var _types = _slicedToArray(types, 3),
          REQUEST = _types[0],
          SUCCESS = _types[1],
          FAILURE = _types[2];
        var fullPromiseDefer;
        var fullPromise = new Promise(function (resolve, reject) {
          fullPromiseDefer = {
            resolve: resolve,
            reject: reject
          };
        });
        fullPromise.catch(function () {
          // no-op: prevent unhandled rejection
          // (since crashes server)
        });
        next(_objectSpread(_objectSpread({}, rest), {}, {
          type: REQUEST,
          _apiRequest: true,
          promise: fullPromise
        }));
        var result = promise.apply(void 0, _toConsumableArray(clients));
        var actionPromise = typeof result === 'function' ? result(dispatch, getState) : result;
        actionPromise.then(function (result) {
          fullPromiseDefer.resolve(result);
          return next(_objectSpread(_objectSpread({}, rest), {}, {
            result: result,
            type: SUCCESS,
            _apiRequestDone: true,
            promise: fullPromise
          }));
        }, function () {
          var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          // NOTE: error can be undefined when internet is offline
          if (error.statusCode === 403 && __CLIENT__) {
            var pathname = getState().router.location.pathname;
            if (pathname.startsWith('/admin')) {
              return next(_objectSpread(_objectSpread({}, rest), {}, {
                error: 'Access denied',
                errorStatusCode: error.statusCode,
                type: FAILURE,
                _apiRequestDone: true,
                promise: fullPromise
              }));
            }
            var state = getState();
            var location = state.router.location;
            var query = {
              returnTo: "".concat(location.pathname).concat(location.search).concat(location.hash)
            };
            var path = '/login';
            var samlDomain = (0, _locationExtra.getSamlDomain)(getState());
            if (samlDomain) {
              path = "/login/saml/".concat(samlDomain);
            }
            var oidcDomain = (0, _locationExtra.getOidcDomain)(getState());
            if (oidcDomain) {
              path = "/login/oidc/".concat(oidcDomain);
            }
            window.location.href = urls.base(path, query);
            return;
          }
          fullPromiseDefer.reject(error);
          if (FAILURE) {
            next(_objectSpread(_objectSpread({}, rest), {}, {
              error: error.text,
              errorStatusCode: error.statusCode,
              type: FAILURE,
              _apiRequestDone: true,
              promise: fullPromise
            }));
          }
        }).catch(function (error) {
          console.error('MIDDLEWARE ERROR:', error);
          if (error && error.stack) console.log(error.stack);
          fullPromiseDefer.reject(error);
          if (FAILURE) {
            next(_objectSpread(_objectSpread({}, rest), {}, {
              error: error,
              type: FAILURE,
              promise: fullPromise
            }));
          }
        });
        return actionPromise;
      };
    };
  };
}
module.exports = exports.default;