"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.languageCodes = exports.getLanguageName = exports.default = void 0;
var languages = [
// all avatar and voice is male
{
  "code": "en",
  "name": "English",
  "yepicConfig": {
    "voiceId": "az-en-US-christopher",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "da",
  "name": "Danish",
  "yepicConfig": {
    "voiceId": "az-da-DK-jeppe",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "nl",
  "name": "Dutch",
  "yepicConfig": {
    "voiceId": "az-nl-NL-maarten",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "fr",
  "name": "French",
  "yepicConfig": {
    "voiceId": "az-fr-FR-alain",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "de",
  "name": "German",
  "yepicConfig": {
    "voiceId": "az-de-DE-bernd",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "it",
  "name": "Italian",
  "yepicConfig": {
    "voiceId": "az-it-IT-benigno",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "ja",
  "name": "Japanese",
  "yepicConfig": {
    "voiceId": "az-ja-JP-keita",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "ko",
  "name": "Korean",
  "yepicConfig": {
    "voiceId": "az-ko-KR-injoon",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "no",
  "name": "Norwegian",
  "yepicConfig": {
    "voiceId": "az-nb-NO-finn",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "pl",
  "name": "Polish",
  "yepicConfig": {
    "voiceId": "az-pl-PL-marek",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "pt",
  "name": "Portuguese",
  "yepicConfig": {
    "voiceId": "az-pt-PT-duarte",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "ru",
  "name": "Russian",
  "yepicConfig": {
    "voiceId": "az-ru-RU-dmitry",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "es",
  "name": "Spanish",
  "yepicConfig": {
    "voiceId": "az-es-ES-alvaro",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "zh-CN",
  "name": "Chinese (Simplified)",
  "yepicConfig": {
    "voiceId": "az-zh-CN-yunfeng",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "zh-TW",
  "name": "Chinese (Traditional)",
  "yepicConfig": {
    "voiceId": "az-zh-TW-yunjhe",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "af",
  "name": "Afrikaans",
  "yepicConfig": {
    "voiceId": "az-af-ZA-willem",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "sq",
  "name": "Albanian"
},
// not supported
{
  "code": "am",
  "name": "Amharic"
}, {
  "code": "ilo",
  "name": "Ilocano"
}, {
  "code": "ar",
  "name": "Arabic",
  "yepicConfig": {
    "voiceId": "az-ar-EG-shakir",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
},
// egyptian
{
  "code": "hy",
  "name": "Armenian"
}, {
  "code": "az",
  "name": "Azeerbaijani",
  "yepicConfig": {
    "voiceId": "az-az-AZ-babek",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "eu",
  "name": "Basque"
}, {
  "code": "be",
  "name": "Belarusian"
}, {
  "code": "bn",
  "name": "Bengali",
  "yepicConfig": {
    "voiceId": "az-bn-IN-bashkar",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "bs",
  "name": "Bosnian",
  "yepicConfig": {
    "voiceId": "az-bs-BA-goran",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "bg",
  "name": "Bulgarian",
  "yepicConfig": {
    "voiceId": "az-bg-BG-borislav",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "ca",
  "name": "Catalan",
  "yepicConfig": {
    "voiceId": "az-ca-ES-enric",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "ceb",
  "name": "Cebuano"
}, {
  "code": "co",
  "name": "Corsican"
}, {
  "code": "hr",
  "name": "Croatian",
  "yepicConfig": {
    "voiceId": "az-hr-HR-srecko",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "cs",
  "name": "Czech",
  "yepicConfig": {
    "voiceId": "az-cs-CZ-antonin",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "eo",
  "name": "Esperanto"
}, {
  "code": "et",
  "name": "Estonian",
  "yepicConfig": {
    "voiceId": "az-et-EE-kert",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "fi",
  "name": "Finnish",
  "yepicConfig": {
    "voiceId": "az-fi-FI-harri",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "fy",
  "name": "Frisian"
}, {
  "code": "gl",
  "name": "Galician",
  "yepicConfig": {
    "voiceId": "az-gl-ES-roi",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "ka",
  "name": "Georgian"
}, {
  "code": "el",
  "name": "Greek",
  "yepicConfig": {
    "voiceId": "az-el-GR-nestoras",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "gu",
  "name": "Gujarati"
}, {
  "code": "ht",
  "name": "Haitian Creole"
}, {
  "code": "ha",
  "name": "Hausa"
}, {
  "code": "haw",
  "name": "Hawaiian",
  "yepicConfig": {
    "voiceId": "xxx",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "iw",
  "name": "Hebrew",
  "yepicConfig": {
    "voiceId": "az-he-IL-avri",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "hi",
  "name": "Hindi",
  "yepicConfig": {
    "voiceId": "az-hi-IN-madhur",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "hmn",
  "name": "Hmong"
}, {
  "code": "hu",
  "name": "Hungarian",
  "yepicConfig": {
    "voiceId": "az-hu-HU-tamas",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "is",
  "name": "Icelandic",
  "yepicConfig": {
    "voiceId": "az-is-IS-gunnar",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "ig",
  "name": "Igbo"
}, {
  "code": "id",
  "name": "Indonesian",
  "yepicConfig": {
    "voiceId": "az-id-ID-ardi",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "ga",
  "name": "Irish",
  "yepicConfig": {
    "voiceId": "az-ga-IE-colm",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "jw",
  "name": "Javanese",
  "yepicConfig": {
    "voiceId": "az-jv-ID-dimas",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "kn",
  "name": "Kannada",
  "yepicConfig": {
    "voiceId": "az-kn-IN-gagan",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "kk",
  "name": "Kazakh",
  "yepicConfig": {
    "voiceId": "az-kk-KZ-daulet",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "km",
  "name": "Khmer"
}, {
  "code": "ku",
  "name": "Kurdish"
}, {
  "code": "ky",
  "name": "Kyrgyz"
}, {
  "code": "lo",
  "name": "Lao",
  "yepicConfig": {
    "voiceId": "az-lo-LA-chanthavong",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "la",
  "name": "Latin"
},
// only match Spanish (Latin America), female
{
  "code": "lv",
  "name": "Latvian",
  "yepicConfig": {
    "voiceId": "az-lv-LV-nils",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "lt",
  "name": "Lithuanian",
  "yepicConfig": {
    "voiceId": "az-lt-LT-leonas",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "lb",
  "name": "Luxembourgish"
}, {
  "code": "mk",
  "name": "Macedonian",
  "yepicConfig": {
    "voiceId": "az-mk-MK-aleksandar",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "mg",
  "name": "Malagasy"
}, {
  "code": "ms",
  "name": "Malay",
  "yepicConfig": {
    "voiceId": "az-ms-MY-osman",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "ml",
  "name": "Malayalam",
  "yepicConfig": {
    "voiceId": "az-ml-IN-midhun",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "mt",
  "name": "Maltese",
  "yepicConfig": {
    "voiceId": "az-mt-MT-joseph",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "mi",
  "name": "Maori"
}, {
  "code": "mr",
  "name": "Marathi"
}, {
  "code": "mn",
  "name": "Mongolian"
}, {
  "code": "my",
  "name": "Myanmar (Burmese)"
}, {
  "code": "ne",
  "name": "Nepali"
}, {
  "code": "ny",
  "name": "Nyanja (Chichewa)"
}, {
  "code": "ps",
  "name": "Pashto",
  "yepicConfig": {
    "voiceId": "az-ps-AF-gulnawaz",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "fa",
  "name": "Persian"
}, {
  "code": "pa",
  "name": "Punjabi"
}, {
  "code": "ro",
  "name": "Romanian",
  "yepicConfig": {
    "voiceId": "az-ro-RO-emil",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "sm",
  "name": "Samoan"
}, {
  "code": "gd",
  "name": "Scots Gaelic"
}, {
  "code": "sr",
  "name": "Serbian",
  "yepicConfig": {
    "voiceId": "az-sr-RS-nicholas",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "st",
  "name": "Sesotho"
}, {
  "code": "sn",
  "name": "Shona"
}, {
  "code": "sd",
  "name": "Sindhi"
}, {
  "code": "si",
  "name": "Sinhala (Sinhalese)",
  "yepicConfig": {
    "voiceId": "az-si-LK-sameera",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "sk",
  "name": "Slovak",
  "yepicConfig": {
    "voiceId": "az-sk-SK-lukas",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "sl",
  "name": "Slovenian",
  "yepicConfig": {
    "voiceId": "az-sl-SI-rok",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "so",
  "name": "Somali",
  "yepicConfig": {
    "voiceId": "az-so-SO-muuse",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "su",
  "name": "Sundanese",
  "yepicConfig": {
    "voiceId": "az-su-ID-jajang",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "sw",
  "name": "Swahili",
  "yepicConfig": {
    "voiceId": "az-sw-KE-rafiki",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "sv",
  "name": "Swedish",
  "yepicConfig": {
    "voiceId": "az-sv-SE-mattias",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "tl",
  "name": "Tagalog (Filipino)"
}, {
  "code": "tg",
  "name": "Tajik"
}, {
  "code": "ta",
  "name": "Tamil",
  "yepicConfig": {
    "voiceId": "az-ta-IN-valluvar",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "te",
  "name": "Telugu",
  "yepicConfig": {
    "voiceId": "az-te-IN-mohan",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "th",
  "name": "Thai",
  "yepicConfig": {
    "voiceId": "az-th-TH-niwat",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "tr",
  "name": "Turkish",
  "yepicConfig": {
    "voiceId": "az-tr-TR-ahmet",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "uk",
  "name": "Ukrainian",
  "yepicConfig": {
    "voiceId": "az-uk-UA-ostap",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "ur",
  "name": "Urdu",
  "yepicConfig": {
    "voiceId": "az-ur-IN-salman",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "uz",
  "name": "Uzbek",
  "yepicConfig": {
    "voiceId": "az-uz-UZ-sardor",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "vi",
  "name": "Vietnamese",
  "yepicConfig": {
    "voiceId": "az-vi-VN-namminh",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "cy",
  "name": "Welsh",
  "yepicConfig": {
    "voiceId": "az-cy-GB-aled",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}, {
  "code": "xh",
  "name": "Xhosa"
}, {
  "code": "yi",
  "name": "Yiddish"
}, {
  "code": "yo",
  "name": "Yoruba"
}, {
  "code": "zu",
  "name": "Zulu",
  "yepicConfig": {
    "voiceId": "az-zu-ZA-themba",
    "avatarId": "94989c6e-0a8b-4332-ab93-5f46b96c0f39"
  }
}];
var _default = exports.default = languages;
var languageCodes = exports.languageCodes = languages.map(function (l) {
  return l.code;
});
var getLanguageName = exports.getLanguageName = function getLanguageName(langCode) {
  var lang = languages.find(function (l) {
    return l.code === langCode;
  });
  return lang ? lang.name : langCode;
};