"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setStatusFilter = exports.setSearchKey = exports.setItemsSelected = exports.setFeatureLimitFilter = exports.setFeatureFilter = exports.setContextMenu = exports.removeSelected = exports.default = exports.addSelected = void 0;
var _module = require("../../utils/module");
var _without = _interopRequireDefault(require("lodash/without"));
var _uniq = _interopRequireDefault(require("lodash/uniq"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/admin/planTable/');
var ADD_SELECTED = type('ADD_SELECTED');
var REMOVE_SELECTED = type('REMOVE_SELECTED');
var SET_SEARCH_KEY = type('SET_SEARCH_KEY');
var SET_CONTEXT_MENU = type('SET_CONTEXT_MENU');
var SET_STATUS_FILTER = type('SET_STATUS_FILTER');
var SET_FEATURE_FILTER = type('SET_FEATURE_FILTER');
var SET_FEATURE_LIMIT_FILTER = type('SET_FEATURE_LIMIT_FILTER');
var initialState = {
  selectedItems: [],
  contextMenu: -1,
  searchKey: '',
  statusFilter: 'active',
  featureFilter: -1,
  featureLimitFilter: 'any'
};
var handlers = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ADD_SELECTED, function (state, _ref) {
  var selectedItems = _ref.selectedItems;
  return _objectSpread(_objectSpread({}, state), {}, {
    selectedItems: (0, _uniq.default)([].concat(_toConsumableArray(state.selectedItems), _toConsumableArray(selectedItems))).sort()
  });
}), REMOVE_SELECTED, function (state, _ref2) {
  var selectedItems = _ref2.selectedItems;
  return _objectSpread(_objectSpread({}, state), {}, {
    selectedItems: _without.default.apply(void 0, [state.selectedItems].concat(_toConsumableArray(selectedItems)))
  });
}), SET_CONTEXT_MENU, function (state, _ref3) {
  var contextMenu = _ref3.contextMenu;
  return _objectSpread(_objectSpread({}, state), {}, {
    contextMenu: contextMenu
  });
}), SET_SEARCH_KEY, function (state, _ref4) {
  var searchKey = _ref4.searchKey;
  return _objectSpread(_objectSpread({}, state), {}, {
    searchKey: searchKey
  });
}), SET_STATUS_FILTER, function (state, _ref5) {
  var statusFilter = _ref5.statusFilter;
  return _objectSpread(_objectSpread({}, state), {}, {
    statusFilter: statusFilter
  });
}), SET_FEATURE_FILTER, function (state, _ref6) {
  var featureFilter = _ref6.featureFilter;
  return _objectSpread(_objectSpread({}, state), {}, {
    featureFilter: featureFilter
  });
}), SET_FEATURE_LIMIT_FILTER, function (state, _ref7) {
  var featureLimitFilter = _ref7.featureLimitFilter;
  return _objectSpread(_objectSpread({}, state), {}, {
    featureLimitFilter: featureLimitFilter
  });
});
var _default = exports.default = (0, _module.createReducer)(handlers, initialState);
var addSelected = exports.addSelected = (0, _module.actionCreator)(ADD_SELECTED, 'selectedItems');
var removeSelected = exports.removeSelected = (0, _module.actionCreator)(REMOVE_SELECTED, 'selectedItems');
var setItemsSelected = exports.setItemsSelected = function setItemsSelected(keys, selected) {
  return (selected ? addSelected : removeSelected)(keys);
};
var setContextMenu = exports.setContextMenu = (0, _module.actionCreator)(SET_CONTEXT_MENU, 'contextMenu');
var setSearchKey = exports.setSearchKey = (0, _module.actionCreator)(SET_SEARCH_KEY, 'searchKey');
var setStatusFilter = exports.setStatusFilter = (0, _module.actionCreator)(SET_STATUS_FILTER, 'statusFilter');
var setFeatureFilter = exports.setFeatureFilter = (0, _module.actionCreator)(SET_FEATURE_FILTER, 'featureFilter');
var setFeatureLimitFilter = exports.setFeatureLimitFilter = (0, _module.actionCreator)(SET_FEATURE_LIMIT_FILTER, 'featureLimitFilter');