"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/* eslint-disable no-var */
// It is used by editor and player as well as by nodejs app

(function () {
  var findWhere = typeof require !== 'undefined' ? require('lodash/find') : _.findWhere;
  var map = typeof require !== 'undefined' ? require('lodash/map') : _.map;
  var extend = typeof require !== 'undefined' ? require('lodash/extend') : _.extend;
  var values = typeof require !== 'undefined' ? require('lodash/values') : _.values;
  var range = typeof require !== 'undefined' ? require('lodash/range') : _.range;
  var isEqual = typeof require !== 'undefined' ? require('lodash/isEqual') : _.isEqual;
  var has = typeof require !== 'undefined' ? require('lodash/has') : _.has;

  // NOTE: this is more restrictive than the site version
  // since there is no need to support embed code of external integrations
  var STEP_DESCRIPTION_APP_SANITIZE_RULES = {
    allowedTags: ['b', 'span', 'a', 'br', 'u'],
    allowedAttributes: {
      a: ['href', 'target', 'rel']
    }
  };
  var STEP_DESCRIPTION_SANITIZE_RULES = {
    allowedTags: ['b', 'strong', 'span', 'a', 'br', 'u'],
    allowedAttributes: {
      b: ['style'],
      span: ['style'],
      a: ['href', 'target', 'rel']
    }
  };
  var STEP_INFOTEXT_SANITIZE_RULES = {
    allowedTags: ['b', 'span', 'a', 'br', 'u', 'ul', 'ol', 'li', 'div'],
    allowedAttributes: {
      a: ['href', 'target', 'rel', 'class']
    },
    allowedClasses: {
      '*': ['wysiwyg-text-align-left', 'wysiwyg-text-align-center', 'wysiwyg-text-align-right']
    }
  };
  var STEP_INFOTEXT_EMBED_ALLOWED_HOSTNAMES = ['www.youtube.com', 'player.vimeo.com', 'docs.google.com', 'fast.wistia.net', '1drv.ms'];
  var STEP_INFOTEXT_EMBED_ALLOW_ATTRIBUTE_VALUES = ['accelerometer', 'autoplay', 'clipboard-write', 'encrypted-media', 'gyroscope', 'picture-in-picture', 'web-share', 'fullscreen'];
  var STEP_INFOTEXT_EMBED_SANITIZE_RULES = {
    allowedTags: ['iframe'],
    allowedAttributes: {
      iframe: ['src', 'name', 'width', 'height', 'allowfullscreen', 'frameborder', 'allow']
    },
    transformTags: {
      iframe: function iframe(tagName, attribs) {
        var newAttribs = extend({}, attribs);
        if (newAttribs.allow) {
          newAttribs.allow = newAttribs.allow.split(';').map(function (allowSingle) {
            return allowSingle.trim();
          }).filter(function (allowSingle) {
            return allowSingle && STEP_INFOTEXT_EMBED_ALLOW_ATTRIBUTE_VALUES.includes(allowSingle);
          }).join('; ');
          if (!newAttribs.allow) {
            delete newAttribs.allow;
          }
        }
        return {
          tagName: tagName,
          attribs: newAttribs
        };
      }
    },
    allowedClasses: {
      'iframe': []
    },
    allowedIframeHostnames: STEP_INFOTEXT_EMBED_ALLOWED_HOSTNAMES,
    allowIframeRelativeUrls: false,
    allowedSchemes: ['https']
  };
  var exportStatics = {
    getScaledSize: function getScaledSize(targetSize, containerSize) {
      var containerWidth = containerSize[0];
      var containerHeight = containerSize[1];
      var targetWidth = targetSize[0];
      var targetHeight = targetSize[1];
      var targetScale = Math.min(1,
      // scale-down only
      containerWidth / targetWidth, containerHeight / targetHeight);
      var scaledWidth = targetWidth * targetScale;
      var scaledHeight = targetHeight * targetScale;
      var targetLeft = (containerWidth - scaledWidth) / 2;
      var targetTop = (containerHeight - scaledHeight) / 2;
      return {
        targetWidth: targetWidth,
        targetHeight: targetHeight,
        scaledWidth: scaledWidth,
        scaledHeight: scaledHeight,
        targetLeft: targetLeft,
        targetTop: targetTop,
        targetScale: targetScale
      };
    },
    // copy from iorad.base.Utils
    isFileProtocolURL: function isFileProtocolURL(url) {
      if (!url) return false;
      if (url.startsWith('file://')) return true;
      if (window.location.href.startsWith('file://') && !url.startsWith('https://') && !url.startsWith('http://') && !url.split(':')[0] !== 'data') {
        var a = document.createElement('a');
        a.href = url;
        var absoluteURL = a.href;
        return absoluteURL.startsWith('file://');
      }
      return false;
    },
    loadImage: function loadImage(url, disableCORS) {
      return new Promise(function (resolve, reject) {
        var image = new Image();
        image.onload = function () {
          resolve(image);
        };
        image.onerror = function () {
          console.error('Cannot load image ' + url);
          reject('Cannot load image');
        };
        if (!exportStatics.isFileProtocolURL(url) && !disableCORS) {
          image.crossOrigin = "Anonymous";
        }
        image.src = url;
      });
    },
    getUrlMaskedCanvas: function getUrlMaskedCanvas(imgUrl, mask, maskColorHex) {
      return exportStatics.loadImage(imgUrl).then(function (img) {
        var canvas = document.createElement('canvas');
        var imgWidth = img.width;
        var imgHeight = img.height;
        canvas.setAttribute('width', imgWidth);
        canvas.setAttribute('height', imgHeight);
        var context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, imgWidth, imgHeight);
        var maskRgb = exportStatics.hexToRgb(maskColorHex || '#000000');
        var maskColor = 'rgba(' + maskRgb.join(', ') + ', ' + mask / 100 + ')';
        context.beginPath();
        context.fillStyle = maskColor;
        context.rect(0, 0, imgWidth, imgHeight);
        context.fill();
        return canvas;
      });
    },
    isValidColor: function isValidColor(color) {
      //fast check for this bug: https://github.com/iorad/iorad/issues/1273
      if (color === '#000000') {
        return true;
      }
      if (/^#[a-f0-9]+$/i.test(color) === false) {
        return false;
      }

      // credits to http://stackoverflow.com/questions/6386090/validating-css-color-names
      // var $dummy = $('<div></div>'),
      //   emptyColor = $dummy.css('color');

      // $dummy.css('color', color);
      // return emptyColor !== $dummy.css('color');
      var $dummy = document.createElement("div");
      var emptyColor = $dummy.style.color;
      $dummy.style.color = color;
      return emptyColor !== $dummy.style.color;
    },
    isImageDark: function isImageDark(imgUrl, mask, maskType) {
      return exportStatics.getUrlMaskedCanvas(imgUrl, mask, maskType).then(function (canvas) {
        var imageData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);

        // examine every pixel
        var darkometer = 0;
        var transparentPixels = 0;
        for (var i = 0; i < imageData.data.length; i += 4) {
          var r = imageData.data[i];
          var g = imageData.data[i + 1];
          var b = imageData.data[i + 2];
          var a = imageData.data[i + 3];
          if (a === 0) {
            transparentPixels = transparentPixels + 1;
            continue;
          }
          if ((r * 299 + g * 587 + b * 114) / 1000 < 128) {
            darkometer = darkometer + 1;
          }
        }

        // more than half pixels are dark
        var isImageDark = darkometer > imageData.data.length / 8 - transparentPixels / 2;
        return isImageDark;
      });
    },
    isThemeDark: function isThemeDark(opts) {
      return opts.headerBackground && opts.backgroundImageDark !== false || !opts.headerBackground && opts.colorPrimary && exportStatics.getContrastColor(opts.colorPrimary) === '#ffffff';
    },
    getBaseLogo: function getBaseLogo(opts, baseThemes) {
      var isImageTheme = (opts.headerBackground || opts.internalKey === 'image') && opts.internalKey !== 'bright' && opts.internalKey !== 'dark';
      var dark = exportStatics.isThemeDark(opts);
      var imageBase = findWhere(baseThemes, {
        internalKey: 'image'
      }) || {};
      var darkBase = findWhere(baseThemes, {
        internalKey: 'dark'
      }) || {};
      var brightBase = findWhere(baseThemes, {
        internalKey: 'bright'
      }) || {};
      var contrastBase = dark ? darkBase : brightBase;
      var imageBaseLogoName = dark ? 'logoImageDark' : 'logoImage';
      var baseTheme = isImageTheme ? imageBase : contrastBase;
      var baseLogo =
      // apply specific base logo for image theme if specified
      (isImageTheme ? imageBase[imageBaseLogoName] : '') || contrastBase.logoImage;
      if (!baseLogo) return '';
      var baseLogoCached = baseLogo + '?lastModified=' + baseTheme.lastModified;
      return baseLogoCached;
    },
    getBaseFavicon: function getBaseFavicon(opts, baseThemes) {
      var isImageTheme = (opts.headerBackground || opts.internalKey === 'image') && opts.internalKey !== 'bright' && opts.internalKey !== 'dark';
      var dark = exportStatics.isThemeDark(opts);
      var imageBase = findWhere(baseThemes, {
        internalKey: 'image'
      }) || {};
      var darkBase = findWhere(baseThemes, {
        internalKey: 'dark'
      }) || {};
      var brightBase = findWhere(baseThemes, {
        internalKey: 'bright'
      }) || {};
      var contrastBase = dark ? darkBase : brightBase;
      var imageBaseFaviconName = dark ? 'faviconImageDark' : 'faviconImage';
      var baseTheme = isImageTheme ? imageBase : contrastBase;
      var baseFavicon =
      // apply specific base favicon for image theme if specified
      (isImageTheme ? imageBase[imageBaseFaviconName] : '') || contrastBase.faviconImage;
      if (!baseFavicon) return '';
      var baseFaviconCached = baseFavicon + '?lastModified=' + baseTheme.lastModified;
      return baseFaviconCached;
    },
    getBaseContrastTheme: function getBaseContrastTheme(opts, baseThemes) {
      var dark = exportStatics.isThemeDark(opts);
      var baseColorTheme = findWhere(baseThemes, {
        internalKey: dark ? 'dark' : 'bright'
      }) || {};
      var baseTheme = extend({}, baseColorTheme); // avoid modifying the original
      return baseTheme;
    },
    // paying for non-extendable design from before, returns hex string
    getHeaderMaskColor: function getHeaderMaskColor(theme) {
      var attrValue = theme.headerMaskType;
      return attrValue === 'black' || !attrValue ? '#000000' : attrValue === 'white' ? '#ffffff' : attrValue;
    },
    // Some colors (font, highlight) are calculated
    // to contrast with the background.
    // We just detect if background is dark or bright
    // and choose colors respectevely.
    // Note, that some contrast colors change depending on
    // whether the cover is expanded or collapsed
    // since they are visible in both cases and
    // cover can have different bg colors, expanded vs collapsed
    themeToItemColors: function themeToItemColors(theme, baseThemes, activeThemeId, coverOpen) {
      var hex6 = exportStatics.colorToHex6;

      // === theme options
      var customOpts = theme ? theme : {};
      var customHeaderMaskColor = theme && exportStatics.getHeaderMaskColor(theme);
      // set only if value is not empty, so it doesn't override base theme
      if (customHeaderMaskColor) {
        customOpts.headerMaskColor = customHeaderMaskColor;
      }
      var themeId = isFinite(activeThemeId) ? activeThemeId : null;
      var baseTheme = customOpts.baseTheme ? findWhere(values(baseThemes), {
        name: customOpts.baseTheme
      }) : findWhere(values(baseThemes), {
        id: themeId
      }) || !theme && findWhere(values(baseThemes), {
        internalKey: 'bright'
      });
      if (baseTheme && baseTheme.headerMaskType) {
        baseTheme.headerMaskColor =
        // old style support
        baseTheme.headerMaskType === 'white' ? '#ffffff' : baseTheme.headerMaskType === 'black' ? '#000000' : baseTheme.headerMaskType ? baseTheme.headerMaskType : '#000000';
      }
      var opts = extend({}, baseTheme || {}, customOpts);
      if (customOpts.logoImage && !customOpts.headerBackground) {
        // don't use default background if custom logo (see #6128)
        opts.headerBackground = null;
      }
      if (customOpts.headerMaskColor && !customOpts.headerBackground) {
        // don't use custom mask if not using custom background (#6525)
        opts.headerMaskColor = baseTheme && baseTheme.headerMaskColor || null;
      }
      var baseContrastTheme = exportStatics.getBaseContrastTheme(opts, baseThemes);

      // === theme generals
      var coverBackgroundImage = opts.headerBackground ? opts.headerBackground + (opts.lastModified && opts.headerBackground.indexOf('cache=') === -1 ? (opts.headerBackground.indexOf('?') === -1 ? '?' : '&') + 'cache=' + opts.lastModified : '') : null;
      var mask = coverBackgroundImage ? Math.max(0, Math.min(100, parseFloat(opts.headerMask) || 0)) : 0;
      var maskColorRgb = opts.headerMaskColor && exportStatics.hexToRgb(opts.headerMaskColor) || [0, 0, 0];
      var maskColor = 'rgba(' + maskColorRgb.join(', ') + ', ' + mask / 100 + ')';
      var playerMenuBackgroundImage = coverBackgroundImage;
      var primary = opts.colorPrimary && !coverBackgroundImage ? hex6(opts.colorPrimary) : baseContrastTheme.colorPrimary;
      var primaryCont = exportStatics.getContrastColor(primary);
      var primaryDark = primaryCont === '#ffffff';
      var progressCont = primaryDark ? 'AAAAAA' : '000000';
      var addTransparency = exportStatics.addTransparency;

      // === theme specials
      // = header
      var hFont = customOpts.headerFontColor || primaryCont;
      var hFontContrast = exportStatics.getContrastColor(hFont);
      var hFontDark = hFont === '#ffffff';
      var header = {
        mask: mask,
        headerMaskColor: opts.headerMaskColor,
        primaryCont: primaryCont,
        coverBackground: coverBackgroundImage ? maskColor : primary,
        collapsedCoverBackground: playerMenuBackgroundImage ? maskColor : primary,
        font: hFont,
        dark: primaryDark,
        coverBackgroundImage: coverBackgroundImage,
        playerMenuBackgroundImage: playerMenuBackgroundImage,
        backgroundImage: coverOpen ? coverBackgroundImage : playerMenuBackgroundImage,
        activeMenuItemBackground: addTransparency(primaryCont, 2),
        progressFirst: addTransparency(progressCont, 3),
        progressSecond: addTransparency(progressCont, 6),
        borderColor: primaryDark ? '#000000' : '#f4f4f4',
        borderColorHover: hFont,
        buttonBackground: hFont,
        buttonBackgroundHover: hFontDark ? '#b2b2b2' // darken(white, 30%)
        : '#4c4c4c',
        // lighten(black, 30%)
        buttonFont: hFontDark ? '#000000' : '#ffffff',
        helpCenterButtonBackground: hFontDark ? addTransparency('#ffffff', '20') : addTransparency('#000000', '05'),
        helpCenterButtonBackgroundHover: hFontDark ? '#ffffff' : '#000000',
        helpCenterButtonFont: hFontDark ? '#ffffff' : '#000000',
        helpCenterButtonFontHover: hFontDark ? '#000000' : '#ffffff',
        outline: addTransparency(hFont, 5)
      };
      // = popup
      var pBackground = primary;
      var pFont = customOpts.headerFontColor || primaryCont;
      var pDark = header.dark;
      var popup = {
        background: header.backgroundImage ? pDark ? 'rgba(0, 0, 0, .90)' : 'rgba(255, 255, 255, 1)' : pBackground,
        font: pFont,
        contentBackground: !header.backgroundImage && pDark ? addTransparency(pFont, 2) : 'rgba(0, 0, 0, 0)',
        buttonBackground: !header.backgroundImage && pDark ? addTransparency(pFont, 2) : 'rgba(0, 0, 0, 0)',
        buttonBackgroundHover: !header.backgroundImage && pDark ? addTransparency(pFont, 3) : 'rgba(0, 0, 0, 0)',
        buttonBorder: !header.backgroundImage && pDark ? 'rgba(0, 0, 0, 0)' : addTransparency(pFont, 2),
        buttonBorderHover: !header.backgroundImage && pDark ? 'rgba(0, 0, 0, 0)' : addTransparency(pFont, 3),
        dropDownHoverBackground: addTransparency(pFont, 1)
      };
      // = finish
      var finish = {
        background: primary,
        font: primaryCont,
        mutedFont: addTransparency(primaryCont, 2),
        buttonBackground: primaryDark ? addTransparency(primaryCont, 2) : primary,
        buttonBackgroundHover: primaryDark ? addTransparency(primaryCont, 3) : primary,
        buttonBorder: primaryDark ? '#00000000' : addTransparency(primaryCont, 2),
        buttonBorderHover: primaryDark ? '#00000000' : addTransparency(primaryCont, 3)
      };
      var logoOpts = theme ? theme : baseThemes;
      var logoCroppedImage = !logoOpts.logoImage ? logoOpts.logoImage : logoOpts.logoImage.split('.').slice(0, -1).join('.') + '_cropped.' + logoOpts.logoImage.split('.').pop();
      var logo = {
        image: logoOpts.logoImage,
        croppedImage: logoCroppedImage,
        href: logoOpts.logoHref,
        targetBlank: logoOpts.logoTargetBlank,
        baseImage: exportStatics.getBaseLogo(baseContrastTheme, baseThemes),
        isBaseTheme: logoOpts.isBaseTheme,
        logoCropped: !!logoOpts.logoCropped
      };
      var faviconOpts = theme ? theme : baseThemes;
      var faviconCroppedImage = !faviconOpts.faviconImage ? faviconOpts.faviconImage : faviconOpts.faviconImage.split('.').slice(0, -1).join('.') + '_cropped.' + faviconOpts.faviconImage.split('.').pop();
      var favicon = {
        image: faviconOpts.faviconImage,
        croppedImage: faviconCroppedImage,
        baseImage: exportStatics.getBaseFavicon(baseContrastTheme, baseThemes),
        isBaseTheme: faviconOpts.isBaseTheme,
        faviconCropped: !!faviconOpts.faviconCropped
      };
      var fontOpts = theme ? theme : baseThemes;
      var font = {
        primaryFamily: fontOpts.primaryFontFamily || 'Libre Franklin',
        secondaryFamily: fontOpts.secondaryFontFamily || 'Fahkwang',
        stepTextFontSize: fontOpts.stepTextFontSize || 15
      };
      var closedCaptioningOpts = theme ? theme : baseThemes;
      var closedCaptioning = {
        ccFontFamily: closedCaptioningOpts.ccFontFamily || 'Poppins',
        ccFontColor: closedCaptioningOpts.ccFontColor || '#ffffff',
        ccFontSize: closedCaptioningOpts.ccFontSize || 16,
        ccBackgroundColor: closedCaptioningOpts.ccBackgroundColor || '#000000',
        ccBackgroundOpacity: closedCaptioningOpts.ccBackgroundOpacity || 100
      };
      closedCaptioning.ccBackgroundColorRgba = 'rgba(' + exportStatics.hexToRgb(closedCaptioning.ccBackgroundColor).join(', ') + ', ' + Math.max(0, Math.min(100, closedCaptioning.ccBackgroundOpacity)) / 100 + ')';
      return {
        header: header,
        popup: popup,
        finish: finish,
        logo: logo,
        favicon: favicon,
        font: font,
        cc: closedCaptioning
      };
    },
    addTransparency: function addTransparency(hexColor, alpha) {
      var rgbList = exportStatics.hexToRgb(hexColor);
      return rgbList && 'rgba(' + rgbList.join(', ') + ', .' + alpha + ')';
    },
    getDefaultLogoUrl: function getDefaultLogoUrl(staticRoot) {
      if (typeof staticRoot === 'undefined') {
        staticRoot = window.STATIC_ROOT || '';
      }
      return staticRoot + 'images/base/logo/logo-new-square-small.svg';
    },
    getDefaultFaviconUrl: function getDefaultFaviconUrl(staticRoot) {
      if (typeof staticRoot === 'undefined') {
        staticRoot = window.STATIC_ROOT || '';
      }
      return staticRoot + 'images/base/favicon.ico';
    },
    getContrastColor: function getContrastColor(color) {
      return !color ? '' : exportStatics.contrastColor(exportStatics.colorToHexTriplet(color));
    },
    // YIQ contrast - credits to http://24ways.org/2010/calculating-color-contrast/
    contrastColor: function contrastColor(hexTripletColor) {
      var color = hexTripletColor;
      color = color.substring(1); // remove #
      var r = parseInt(color.substr(0, 2), 16);
      var g = parseInt(color.substr(2, 2), 16);
      var b = parseInt(color.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? '#000000' : '#ffffff';
    },
    // convert color (eg 'red', 'rgb(255,0,0)', '#ff000000') to hex triplet (eg '#ff000000')
    colorToHexTriplet: function colorToHexTriplet(color) {
      if (!color) return color;
      if (exportStatics.startsWith(color, '#')) return exportStatics.normalizeHex(color);else if (exportStatics.startsWith(color, 'rgba')) {
        color = exportStatics.rgbaToRgb(color);
      } else if (!exportStatics.startsWith(color, 'rgb')) {
        // is a word, convert it to rgb first
        color = exportStatics.colorWordToRgb(color);
      }
      return color ? exportStatics.rgbToHex(color) : '';
    },
    colorToHex6: function colorToHex6(color) {
      return exportStatics.colorToHexTriplet(color).substr(0, 7);
    },
    // normalize hex value into 8 digit format (#ff00aaff)
    normalizeHex: function normalizeHex(color) {
      if (!exportStatics.startsWith(color, '#')) return color;
      if (color.length === 4 || color.length === 5) {
        return color[0] + color[1] + color[1] + color[2] + color[2] + color[3] + color[3] + (color[4] || 'f') + (color[4] || 'f');
      }
      if (color.length === 7) return color + 'ff';
      return color;
    },
    // rgba string to rgb string
    // based on http://stackoverflow.com/a/2645218
    rgbaToRgb: function rgbaToRgb(rgbaColor) {
      var rgbaArray = exportStatics.rgbaToArray(rgbaColor);

      // background is directly extracted from the rgba color!
      // TODO: check if this works in all cases
      var backgroundRgbArray = [rgbaArray[0], rgbaArray[1], rgbaArray[2]];

      // normalize
      function normalize(array) {
        return map(array, function (item) {
          return item / 255;
        });
      }
      rgbaArray = normalize(rgbaArray);
      backgroundRgbArray = normalize(backgroundRgbArray);
      var rgbArray = [];
      rgbArray[0] = (1 - rgbaArray[3]) * backgroundRgbArray[0] + rgbaArray[3] * rgbaArray[0];
      rgbArray[1] = (1 - rgbaArray[3]) * backgroundRgbArray[1] + rgbaArray[3] * rgbaArray[1];
      rgbArray[2] = (1 - rgbaArray[3]) * backgroundRgbArray[2] + rgbaArray[3] * rgbaArray[2];

      // denormalize
      function denormalize(array) {
        return map(array, function (item) {
          return item * 255;
        });
      }
      rgbArray = denormalize(rgbArray);
      return 'rgb(' + rgbArray[0] + ',' + rgbArray[1] + ',' + rgbArray[2] + ')';
    },
    // rgba string to array
    rgbaToArray: function rgbaToArray(rgbaColor) {
      var rgbaArray = rgbaColor.split(",");
      rgbaArray[0] = rgbaArray[0].substr(rgbaArray[0].indexOf('(') + 1); // from " rgb( XYZ " to " XYZ "
      rgbaArray[3] = rgbaArray[3].substr(0, rgbaArray[3].indexOf(')')); // from " XYZ ) " to "  XYZ "
      rgbaArray = map(rgbaArray, function (value) {
        return parseInt(value.trim(), 10);
      });
      return rgbaArray;
    },
    // eg from 'red' to 'rgb(255,0,0)'
    // Note: not super efficient, rely on getComputedStyle (IE9+)
    colorWordToRgb: function colorWordToRgb(colorWord) {
      // node version
      if (typeof require !== 'undefined') {
        var convert = require('color-convert');
        var rgbArray = convert.keyword.rgb(colorWord);
        return rgbArray && 'rgb(' + rgbArray.join(',') + ')';
      }
      var div = document.createElement('div');
      div.style.color = colorWord;
      div.style.display = 'none';
      document.body.appendChild(div);
      var rgbColor = window.getComputedStyle(div).color;
      if (!div.style.color) {
        // invalid color, computed style will inherit the parent
        // which will provide value that is not meant, return empty
        return '';
      }
      document.body.removeChild(div);
      return rgbColor;
    },
    // accepts string (eg rgb(255,255,255)) or array (eg [255,255,255])
    rgbToHex: function rgbToHex(rgb) {
      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? '0' + hex : hex;
      }
      var rgbArray = typeof rgb == 'string' ? exportStatics.rgbToArray(rgb) : rgb;
      return '#' + componentToHex(rgbArray[0]) + componentToHex(rgbArray[1]) + componentToHex(rgbArray[2]);
    },
    // rgb string to array
    rgbToArray: function rgbToArray(rgbColor) {
      var rgbArray = rgbColor.split(',');
      rgbArray[0] = rgbArray[0].substr(rgbArray[0].indexOf('(') + 1); // from " rgb( XYZ " to " XYZ "
      rgbArray[2] = rgbArray[2].substr(0, rgbArray[2].indexOf(')')); // from " XYZ ) " to "  XYZ "
      rgbArray = map(rgbArray, function (value) {
        return parseInt(value.trim(), 10);
      });
      return rgbArray;
    },
    // hex (eg #fff to rgb(255, 255, 255))
    hexToRgb: function hexToRgb(hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      });
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
    },
    removeBlanksFromImage: function removeBlanksFromImage(img) {
      var canvas = document.createElement('canvas');
      var imgWidth = img.width;
      var imgHeight = img.height;
      canvas.setAttribute('width', imgWidth);
      canvas.setAttribute('height', imgHeight);
      var context = canvas.getContext('2d');
      context.drawImage(img, 0, 0);
      var croppedCanvas = document.createElement('canvas');
      var rect = exportStatics.extractNoBlankRectFromCanvas(canvas, imgWidth, imgHeight);
      croppedCanvas.setAttribute('width', rect.width);
      croppedCanvas.setAttribute('height', rect.height);
      return exportStatics.cropCanvas(canvas, croppedCanvas, rect.top, rect.bottom, rect.left, rect.right, rect.width, rect.height);
    },
    // CLIENT SIDE ONLY!
    extractNoBlankRectFromCanvas: function extractNoBlankRectFromCanvas(canvas, imgWidth, imgHeight) {
      var context = canvas.getContext('2d');
      var imageData = context.getImageData(0, 0, imgWidth, imgHeight);
      var data = imageData.data;
      var getRBG = function getRBG(x, y) {
        var offset = imgWidth * y + x;
        return {
          red: data[offset * 4],
          green: data[offset * 4 + 1],
          blue: data[offset * 4 + 2],
          opacity: data[offset * 4 + 3]
        };
      };
      var isBlank = function isBlank(rgb) {
        // many images contain noise, as the white is not a pure #fff white
        return rgb.red > 200 && rgb.green > 200 && rgb.blue > 200 || rgb.opacity === 0;
      };
      function isPixelEqualRow(pixel, y) {
        return function (x) {
          var rgb = getRBG(x, y);
          return isEqual(pixel, rgb);
        };
      }
      function isPixelEqualColumn(pixel, x) {
        return function (y) {
          var rgb = getRBG(x, y);
          return isEqual(pixel, rgb);
        };
      }
      var scanY = function scanY(fromTop) {
        var offset = fromTop ? 1 : -1;

        // loop through each row
        for (var y = fromTop ? 0 : imgHeight - 1; fromTop ? y < imgHeight : y > -1; y += offset) {
          // loop through each column
          var firstRGB = getRBG(0, y);
          var cropLine = isBlank(firstRGB) && range(1, imgWidth).every(isPixelEqualRow(firstRGB, y));
          if (!cropLine) return fromTop ? y : y + 1;
        }
        return null; // all image is white
      };
      var scanX = function scanX(fromLeft, cropTop, cropBottom) {
        var offset = fromLeft ? 1 : -1;

        // loop through each column
        for (var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? x < imgWidth : x > -1; x += offset) {
          // loop through each row
          var firstRGB = getRBG(x, 0);
          var cropLine = isBlank(firstRGB) && range(cropTop, cropBottom).every(isPixelEqualColumn(firstRGB, x));
          if (!cropLine) return fromLeft ? x : x + 1;
        }
        return null; // all image is white
      };
      var top = scanY(true);
      var bottom = scanY(false);
      var left = scanX(true, top, bottom);
      var right = scanX(false, top, bottom);
      var width = right - left;
      var height = bottom - top;
      return {
        top: top,
        bottom: bottom,
        left: left,
        right: right,
        width: width,
        height: height
      };
    },
    cropCanvas: function cropCanvas(originalCanvas, croppedCanvas, top, bottom, left, right, width, height) {
      croppedCanvas.getContext('2d').drawImage(originalCanvas, left, top, width, height, 0, 0, width, height);
      return croppedCanvas;
    },
    startsWith: function startsWith(target, search) {
      return target.indexOf(search) == 0;
    },
    trimLeft: function trimLeft(target) {
      return target.replace(/^\s+/, "");
    },
    trimRight: function trimRight(target) {
      return target.replace(/\s+$/, "");
    },
    // based on http://stackoverflow.com/a/4004010
    getCookies: function getCookies() {
      var c = document.cookie,
        v = 0,
        cookies = {};
      if (document.cookie.match(/^\s*\$Version=(?:"1"|1);\s*(.*)/)) {
        c = RegExp.$1;
        v = 1;
      }
      if (v === 0) {
        map(c.split(/[,;]/), function (cookie) {
          var parts = cookie.split(/=/, 2),
            name = decodeURIComponent(exportStatics.trimLeft(parts[0])),
            value = parts.length > 1 ? decodeURIComponent(exportStatics.trimRight(parts[1])) : null;
          cookies[name] = value;
        });
      } else {
        c.match(/(?:^|\s+)([!#$%&'*+\-.0-9A-Z^`a-z|~]+)=([!#$%&'*+\-.0-9A-Z^`a-z|~]*|"(?:[\x20-\x7E\x80\xFF]|\\[\x00-\x7F])*")(?=\s*[,;]|$)/g).map(function ($0, $1) {
          var name = $0,
            value = $1.charAt(0) === '"' ? $1.substr(1, -1).replace(/\\(.)/g, "$1") : $1;
          cookies[name] = value;
        });
      }
      return cookies;
    },
    getCookie: function getCookie(name) {
      var cookies = exportStatics.getCookies();
      return has(cookies, name) ? cookies[name] : '';
    },
    setCookie: function setCookie(name, value, days, domain, path) {
      var expires = '';
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
      }
      document.cookie = name + '=' + (value || '') + expires + (domain ? '; domain=' + domain : '') + (path ? '; path=' + path : '') + '; SameSite=None; Secure';
    },
    // based on https://stackoverflow.com/a/33366171
    clearAllCookies: function clearAllCookies(name) {
      var cookies = document.cookie.split('; ');
      for (var c = 0; c < cookies.length; c++) {
        var cookieName = encodeURIComponent(cookies[c].split(';')[0].split('=')[0]);
        if (!name || cookieName === name) {
          document.cookie = cookieName + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/'; // no domain, root path
        }
        var d = window.location.hostname.split('.');
        while (d.length > 0) {
          var cookieBase = cookieName + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
          var p = location.pathname.split('/');
          if (!name || cookieName === name) {
            document.cookie = cookieBase + '/'; // with domain, root path
          }
          while (p.length > 0) {
            if (!name || cookieName === name) {
              document.cookie = cookieBase + p.join('/'); // with domain, with path
            }
            p.pop();
          }
          d.shift();
        }
      }
      // https://github.com/iorad/iorad/issues/9799
      if (exportStatics.getCookie(name)) {
        exportStatics.setCookie(name, '', -1, window.location.hostname, '/');
        exportStatics.setCookie(name, '', -1, '.' + window.location.hostname, '/');
        exportStatics.setCookie(name, '', -1, window.location.hostname.split('.').slice(-2).join('.'), '/');
        exportStatics.setCookie(name, '', -1, '.' + window.location.hostname.split('.').slice(-2).join('.'), '/');
      }
    },
    isCookieConsentAccepted: function isCookieConsentAccepted(type) {
      // exception for player & help-center (library)
      if (window.location.pathname.startsWith('/player/') || window.location.pathname.startsWith('/library/') || window.location.pathname.startsWith('/help-center/')) {
        return true;
      }
      var cookies = exportStatics.getCookies();
      if (cookies.cookieconsent_status_v3 === 'allow') return true;
      if (type && cookies.cookieconsent_status_v3 === 'allow-selection') {
        var allowList = (cookies.cookieconsent_status_v3_selection || '').split('|');
        var groupValue = type.split('.')[0];
        return allowList.includes(type) || allowList.includes(groupValue);
      }
      return false;
    },
    // replace all &amp;nbsp; to &nbsp;
    // replace all &amp;amp; to &amp;
    fixInfotextTitle: function fixInfotextTitle(title) {
      if (!title) return title;
      return title.replace(/&amp;nbsp;/g, '&nbsp;').replace(/&amp;amp;/g, '&amp;');

      // var openTag = '<b>';
      // var closeTag = '</b>';
      // if (!title.startsWith(openTag)) return title;

      // function replaceText(text) {
      //   var firstText = openTag + text;
      //   var lastText = text + closeTag;

      //   if (title.startsWith(firstText)) {
      //     title = openTag + ' ' + title.substring(firstText.length, title.length);
      //   }

      //   if (title.endsWith(lastText)) {
      //     title = title.substring(0, title.length - lastText.length) + ' ' + closeTag;
      //   }
      // }

      // replaceText('&nbsp;');

      // replaceText('&amp;nbsp;');

      // return title;
    },
    STEP_DESCRIPTION_APP_SANITIZE_RULES: STEP_DESCRIPTION_APP_SANITIZE_RULES,
    STEP_DESCRIPTION_SANITIZE_RULES: STEP_DESCRIPTION_SANITIZE_RULES,
    STEP_INFOTEXT_SANITIZE_RULES: STEP_INFOTEXT_SANITIZE_RULES,
    STEP_INFOTEXT_EMBED_ALLOWED_HOSTNAMES: STEP_INFOTEXT_EMBED_ALLOWED_HOSTNAMES,
    STEP_INFOTEXT_EMBED_SANITIZE_RULES: STEP_INFOTEXT_EMBED_SANITIZE_RULES,
    tutorialLayoutVisibilityOptions: [{
      key: 'is_playbar_disabled',
      label: 'Playbar',
      group: 'playbar_navigation'
    }, {
      key: 'is_navigation_disabled',
      label: 'Navigation',
      group: 'playbar_navigation'
    }, {
      key: 'is_progress_bar_disabled',
      label: 'Progress Bar',
      group: 'playbar_navigation'
    }, {
      key: 'is_step_counter_disabled',
      label: 'Step Counter',
      group: 'playbar_navigation'
    }, {
      key: 'is_sharing_disabled',
      label: 'Share',
      group: 'playbar_navigation'
    }, {
      key: 'is_language_disabled',
      label: 'Language',
      group: 'playbar_navigation'
    }, {
      key: 'is_fullscreen_disabled',
      label: 'Fullscreen',
      group: 'playbar_navigation'
    }, {
      key: 'is_commenting_disabled',
      label: 'Comments',
      group: 'playbar_navigation'
    }, {
      key: 'is_recommended_disabled',
      label: 'Recommended',
      group: 'playbar_navigation'
    }, {
      key: 'is_cover_disabled',
      label: 'Cover',
      group: 'cover_appearance'
    }, {
      key: 'is_logo_disabled',
      label: 'Logo',
      group: 'cover_appearance'
    }, {
      key: 'is_favicon_disabled',
      label: 'Favicon',
      group: 'cover_appearance'
    }, {
      key: 'is_info_disabled',
      label: 'Creator & Edit Date',
      group: 'cover_appearance'
    }, {
      key: 'is_duration_disabled',
      label: 'Duration',
      group: 'cover_appearance'
    }, {
      key: 'is_stepsnumber_disabled',
      label: 'Steps Number',
      group: 'cover_appearance'
    }, {
      key: 'is_actionlabel_disabled',
      label: 'Action Label',
      group: 'cover_appearance'
    }, {
      key: 'is_description_disabled',
      label: 'Info',
      group: 'cover_appearance'
    }, {
      key: 'is_trysteps_disabled',
      label: 'Try it',
      group: 'modes_outputs'
    }, {
      key: 'is_viewsteps_disabled',
      label: 'Scroll it',
      group: 'modes_outputs'
    }, {
      key: 'is_mobilequick_disabled',
      label: 'Slide it',
      group: 'modes_outputs'
    }, {
      key: 'is_watchsteps_disabled',
      label: 'Watch it',
      group: 'modes_outputs'
    }, {
      key: 'is_quizsteps_disabled',
      label: 'Quiz it',
      group: 'modes_outputs'
    }, {
      key: 'is_live_disabled',
      label: 'Do it',
      group: 'modes_outputs'
    }, {
      key: 'is_print_portrait_disabled',
      label: 'PDF Portrait',
      group: 'modes_outputs'
    }, {
      key: 'is_print_landscape_disabled',
      label: 'PDF Landscape',
      group: 'modes_outputs'
    }, {
      key: 'is_print_qr_disabled',
      label: 'PDF QR',
      group: 'modes_outputs'
    }

    // { key: 'is_step_image_preview_disabled', label: 'Step Image Preview' },
    // { key: 'is_ask_thesquare_disabled', label: 'Ask The Square' },
    ],
    tutorialLayoutDevicesOptions: [{
      key: 'is_adaptive_device',
      label: 'Adaptive',
      desc: 'Player is responsive to the device it\'s being viewed on.',
      isDefault: function isDefault() {
        return true;
      }
    }, {
      key: 'is_desktop_device',
      label: 'Desktop',
      desc: 'Force the desktop player, no matter how it\'s being viewed.'
    }, {
      key: 'is_mobile_device',
      label: 'Mobile',
      desc: 'Force the mobile player, no matter how it\'s being viewed.'
    }],
    tutorialLayoutDefaultViewOptions: [{
      key: 'is_trysteps_default',
      label: 'Try it',
      isDefault: function isDefault(mode) {
        return mode !== 'widget';
      } // default to "Try It" in other modes
    }, {
      key: 'is_viewsteps_default',
      label: 'Scroll it'
    }, {
      key: 'is_mobilequick_default',
      label: 'Slide it',
      isDefault: function isDefault(mode) {
        return mode === 'widget';
      } // default to "Slide It" in widget
    }, {
      key: 'is_watchsteps_default',
      label: 'Watch it'
    }, {
      key: 'is_quizsteps_default',
      label: 'Quiz it'
    },
    // { key: 'is_pdf_default', label: 'Print it' },
    {
      key: 'is_live_default',
      label: 'Do it'
    }],
    tutorialLayoutScreenshotZoomOptions: [{
      key: 'is_adaptive_screenshot_zoom',
      label: '100%',
      isDefault: function isDefault() {
        return true;
      }
    }, {
      key: 'is_75_screenshot_zoom',
      label: '75%'
    }, {
      key: 'is_half_screenshot_zoom',
      label: '50%'
    }, {
      key: 'is_full_screenshot_zoom',
      label: '0% (full screenshot)'
    }],
    tutorialLayoutStepPanelOptions: [{
      key: 'is_full_step_panel',
      label: 'Expanded',
      desc: 'Fully expand the instruction and dock it to the right.'
    }, {
      key: 'is_minimized_step_panel',
      label: 'Minimized',
      desc: 'Default the instruction to the bottom right corner.',
      isDefault: function isDefault() {
        return true;
      },
      replaceKeys: ['is_steptext_step_panel'] // Handle REMOVED option: Steptext hidden => Minimized
    }, {
      key: 'is_steptext_step_panel',
      label: 'Only Step Text',
      desc: '',
      hidden: true // REMOVED: keep in JS list for setting to false when selecting other options
    }, {
      key: 'is_navbar_step_panel',
      label: 'Collapsed',
      desc: 'Collapse the instruction - allows learner to expand.'
    }, {
      key: 'is_hidden_step_panel',
      label: 'Hidden',
      desc: 'Hide the text instructions all together.'
    }]
  };
  if ((typeof module === "undefined" ? "undefined" : _typeof(module)) === 'object') {
    module.exports = exportStatics;
  } else {
    window.iorad = window.iorad || {};
    window.iorad.base = window.iorad.base || {};
    window.iorad.base.Export = exportStatics;
  }
})();