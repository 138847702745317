"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeLostPassword = changeLostPassword;
exports.check2FALogin = check2FALogin;
exports.clearEmailError = clearEmailError;
exports.clearLoginError = clearLoginError;
exports.clearSignupError = clearSignupError;
exports.default = void 0;
exports.extendAuth = extendAuth;
exports.getUser = void 0;
exports.googleLogin = googleLogin;
exports.googleSignup = googleSignup;
exports.isLoadResetPasswordCodeLoaded = isLoadResetPasswordCodeLoaded;
exports.isLoaded = isLoaded;
exports.isSiteThemeLoaded = isSiteThemeLoaded;
exports.load = load;
exports.loadAuthAndSiteTheme = loadAuthAndSiteTheme;
exports.loadResetPasswordCode = loadResetPasswordCode;
exports.loadedByCredentialless = loadedByCredentialless;
exports.loadedByPluginType = loadedByPluginType;
exports.loadedByReferer = loadedByReferer;
exports.login = login;
exports.loginWithGracehill = loginWithGracehill;
exports.logout = logout;
exports.mainInitialState = void 0;
exports.resendActivation = resendActivation;
exports.resetPassword = resetPassword;
exports.resetUserToken = resetUserToken;
exports.saveUserPreference = void 0;
exports.signup = signup;
exports.start2FALogin = start2FALogin;
exports.toggleIsTermsAgree = toggleIsTermsAgree;
exports.updateChangeLostPasswordForm = updateChangeLostPasswordForm;
exports.updateLoginForm = updateLoginForm;
exports.updateResetPasswordForm = updateResetPasswordForm;
exports.updateSignupForm = updateSignupForm;
exports.validateChangeLostPassword = validateChangeLostPassword;
exports.validateLogin = validateLogin;
exports.validateResetPassword = validateResetPassword;
exports.validateSignup = validateSignup;
var _redux = require("redux");
var _redux2 = require("utils/redux");
var _accountTypes = require("./accountTypes");
var _helpCenterTypes = require("./helpCenterTypes");
var _omit = _interopRequireDefault(require("lodash/fp/omit"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = function type(name) {
  return "redux-example/auth/".concat(name);
};
var LOAD = 'redux-example/auth/LOAD';
var LOAD_SUCCESS = 'redux-example/auth/LOAD_SUCCESS';
var LOAD_FAIL = 'redux-example/auth/LOAD_FAIL';
var LOAD_AUTH_AND_SITE_THEME = 'redux-example/auth/LOAD_AUTH_AND_SITE_THEME';
var LOAD_AUTH_AND_SITE_THEME_SUCCESS = 'redux-example/auth/LOAD_AUTH_AND_SITE_THEME_SUCCESS';
var LOAD_AUTH_AND_SITE_THEME_FAIL = 'redux-example/auth/LOAD_AUTH_AND_SITE_THEME_FAIL';
var EXTEND_AUTH = 'redux-example/auth/EXTEND_AUTH';
var RESET_USER_TOKEN = type('RESET_USER_TOKEN');
var RESET_USER_TOKEN_SUCCESS = type('RESET_USER_TOKEN_SUCCESS');
var RESET_USER_TOKEN_FAIL = type('RESET_USER_TOKEN_FAIL');
var UPDATE_LOGIN_FORM = 'redux-example/auth/UPDATE_LOGIN_FORM';
var UPDATE_LOGIN_ERROR = 'redux-example/auth/UPDATE_LOGIN_ERROR';
var CLEAR_LOGIN_ERROR = 'redux-example/auth/CLEAR_LOGIN_ERROR';
var LOGIN = 'redux-example/auth/LOGIN';
var LOGIN_SUCCESS = 'redux-example/auth/LOGIN_SUCCESS';
var LOGIN_FAIL = 'redux-example/auth/LOGIN_FAIL';
var GOOGLE_LOGIN = 'redux-example/auth/GOOGLE_LOGIN';
var GOOGLE_LOGIN_SUCCESS = 'redux-example/auth/GOOGLE_LOGIN_SUCCESS';
var GOOGLE_LOGIN_FAIL = 'redux-example/auth/GOOGLE_LOGIN_FAIL';
var START_2FA_LOGIN = 'redux-example/auth/START_2FA_LOGIN';
var START_2FA_LOGIN_SUCCESS = 'redux-example/auth/START_2FA_LOGIN_SUCCESS';
var START_2FA_LOGIN_FAIL = 'redux-example/auth/START_2FA_LOGIN_FAIL';
var CHECK_2FA_LOGIN = 'redux-example/auth/CHECK_2FA_LOGIN';
var CHECK_2FA_LOGIN_SUCCESS = 'redux-example/auth/CHECK_2FA_LOGIN_SUCCESS';
var CHECK_2FA_LOGIN_FAIL = 'redux-example/auth/CHECK_2FA_LOGIN_FAIL';
var UPDATE_SIGNUP_FORM = 'redux-example/auth/UPDATE_SIGNUP_FORM';
var TOGGLE_TERMS_AGREE = 'redux-example/auth/TOGGLE_TERMS_AGREE';
var UPDATE_SIGNUP_ERROR = 'redux-example/auth/UPDATE_SIGNUP_ERROR';
var CLEAR_SIGNUP_ERROR = 'redux-example/auth/CLEAR_SIGNUP_ERROR';
var SIGNUP = 'redux-example/auth/SIGNUP';
var SIGNUP_SUCCESS = 'redux-example/auth/SIGNUP_SUCCESS';
var SIGNUP_FAIL = 'redux-example/auth/SIGNUP_FAIL';
var GOOGLE_SIGNUP = 'redux-example/auth/GOOGLE_SIGNUP';
var GOOGLE_SIGNUP_SUCCESS = 'redux-example/auth/GOOGLE_SIGNUP_SUCCESS';
var GOOGLE_SIGNUP_FAIL = 'redux-example/auth/GOOGLE_SIGNUP_FAIL';
var UPDATE_RESETPASSWORD_FORM = 'redux-example/auth/UPDATE_RESETPASSWORD_FORM';
var UPDATE_RESETPASSWORD_ERROR = 'redux-example/auth/UPDATE_RESETPASSWORD_ERROR';
var CLEAR_EMAIL_ERROR = 'redux-example/auth/CLEAR_EMAIL_ERROR';
var RESETPASSWORD = 'redux-example/auth/RESETPASSWORD';
var RESETPASSWORD_SUCCESS = 'redux-example/auth/RESETPASSWORD_SUCCESS';
var RESETPASSWORD_FAIL = 'redux-example/auth/RESETPASSWORD_FAIL';
var UPDATE_CHANGELOSTPASSWORD_FORM = 'redux-example/auth/UPDATE_CHANGELOSTPASSWORD_FORM';
var UPDATE_CHANGELOSTPASSWORD_ERROR = 'redux-example/auth/UPDATE_CHANGELOSTPASSWORD_ERROR';
var CHANGELOSTPASSWORD = 'redux-example/auth/CHANGELOSTPASSWORD';
var CHANGELOSTPASSWORD_SUCCESS = 'redux-example/auth/CHANGELOSTPASSWORD_SUCCESS';
var CHANGELOSTPASSWORD_FAIL = 'redux-example/auth/CHANGELOSTPASSWORD_FAIL';
var LOGOUT = 'redux-example/auth/LOGOUT';
var LOGOUT_SUCCESS = 'redux-example/auth/LOGOUT_SUCCESS';
var LOGOUT_FAIL = 'redux-example/auth/LOGOUT_FAIL';
var LOAD_RESETPASSWORD_CODE = 'redux-example/auth/LOAD_RESETPASSWORD_CODE';
var LOAD_RESETPASSWORD_CODE_SUCCESS = 'redux-example/auth/LOAD_RESETPASSWORD_CODE_SUCCESS';
var LOAD_RESETPASSWORD_CODE_FAIL = 'redux-example/auth/LOAD_RESETPASSWORD_CODE_FAIL';
var LOADED_BY_PLUGIN_TYPE = 'redux-example/auth/LOADED_BY_PLUGIN_TYPE';
var LOADED_BY_REFERER = 'redux-example/auth/LOADED_BY_REFERER';
var LOADED_BY_CREDENTIALLESS = 'redux-example/auth/LOADED_BY_CREDENTIALLESS';
var RESEND_ACTIVATION = 'redux-example/auth/RESEND_ACTIVATION';
var RESEND_ACTIVATION_SUCCESS = 'redux-example/auth/RESEND_ACTIVATION_SUCCESS';
var RESEND_ACTIVATION_FAIL = 'redux-example/auth/RESEND_ACTIVATION_FAIL';
var SAVE_USER_PREFERENCE = type('SAVE_USER_PREFERENCE');
var SAVE_USER_PREFERENCE_SUCCESS = type('SAVE_USER_PREFERENCE_SUCCESS');
var SAVE_USER_PREFERENCE_FAIL = type('SAVE_USER_PREFERENCE_FAIL');
var initialChangeLostPasswordErrorState = {};
function changeLostPasswordError() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialChangeLostPasswordErrorState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case UPDATE_CHANGELOSTPASSWORD_ERROR:
      var newState = _objectSpread({}, state);
      if (action.result) {
        newState[action.result.name] = action.result.value;
      }
      if (action.error) {
        newState.other = (0, _redux2.getActionError)(action, 'other');
      }
      return newState;
    case CHANGELOSTPASSWORD_SUCCESS:
      return initialChangeLostPasswordErrorState;
    case CHANGELOSTPASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        password: (0, _redux2.getActionError)(action, 'password'),
        other: (0, _redux2.getActionError)(action, 'other')
      });
    default:
      return state;
  }
}
var initialChangeLostPasswordFormState = {};
function changeLostPasswordForm() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialChangeLostPasswordFormState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case UPDATE_CHANGELOSTPASSWORD_FORM:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.name, action.value));
    case CHANGELOSTPASSWORD_SUCCESS:
      return initialChangeLostPasswordFormState;
    default:
      return state;
  }
}
var initialResetPasswordErrorState = {};
function resetPasswordError() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialResetPasswordErrorState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case UPDATE_RESETPASSWORD_ERROR:
      var newState = _objectSpread({}, state);
      if (action.result) {
        newState[action.result.name] = action.result.value;
      }
      if (action.error) {
        newState.other = (0, _redux2.getActionError)(action, 'other');
      }
      return newState;
    case RESETPASSWORD_SUCCESS:
      return initialResetPasswordErrorState;
    case CLEAR_EMAIL_ERROR:
      return initialResetPasswordErrorState;
    case RESETPASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        email: (0, _redux2.getActionError)(action, 'email'),
        other: (0, _redux2.getActionError)(action, 'other')
      });
    default:
      return state;
  }
}
var initialResetPasswordFormState = {
  email: ''
};
function resetPasswordForm() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialResetPasswordFormState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case UPDATE_RESETPASSWORD_FORM:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.name, action.value));
    case RESETPASSWORD_SUCCESS:
      return initialResetPasswordFormState;
    default:
      return state;
  }
}
var initialSignupErrorState = {};
function signupError() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialSignupErrorState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case UPDATE_SIGNUP_ERROR:
      var newState = _objectSpread({}, state);
      if (action.result) {
        newState[action.result.name] = action.result.value;
      }
      if (action.error) {
        newState.other = (0, _redux2.getActionError)(action, 'other');
      }
      return newState;
    case CLEAR_SIGNUP_ERROR:
      var newSignupError = _objectSpread({}, state);
      if (action.name === 'email') {
        return initialSignupErrorState;
      } else {
        newSignupError[action.name] && delete newSignupError[action.name];
        return newSignupError;
      }
    case SIGNUP_SUCCESS:
    case GOOGLE_SIGNUP_SUCCESS:
      return initialSignupErrorState;
    case SIGNUP_FAIL:
    case GOOGLE_SIGNUP_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        fullname: (0, _redux2.getActionError)(action, 'fullname'),
        email: (0, _redux2.getActionError)(action, 'email'),
        password: (0, _redux2.getActionError)(action, 'password'),
        other: (0, _redux2.getActionError)(action, 'other')
      });
    default:
      return state;
  }
}
var initialSignupFormState = {
  isTermsAgree: false,
  email: '',
  password: ''
};
function signupForm() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialSignupFormState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case UPDATE_SIGNUP_FORM:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.name, action.value));
    case SIGNUP_SUCCESS:
    case GOOGLE_SIGNUP_SUCCESS:
      return initialSignupFormState;
    default:
      return state;
  }
}
var initialLoginErrorState = {};
function loginError() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialLoginErrorState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case UPDATE_LOGIN_ERROR:
      var newState = _objectSpread({}, state);
      if (action.result) {
        newState[action.result.name] = action.result.value;
      }
      if (action.error) {
        newState.other = (0, _redux2.getActionError)(action, 'other');
      }
      return newState;
    case CLEAR_LOGIN_ERROR:
      var newloginError = _objectSpread({}, state);
      if (action.name === 'email') {
        return initialLoginErrorState;
      } else {
        newloginError[action.name] && delete newloginError[action.name];
        newloginError['other'] && delete newloginError['other'];
        return newloginError;
      }
    case LOGIN_SUCCESS:
    case GOOGLE_LOGIN_SUCCESS:
      return initialLoginErrorState;
    case LOGIN_FAIL:
    case GOOGLE_LOGIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        email: (0, _redux2.getActionError)(action, 'email'),
        password: (0, _redux2.getActionError)(action, 'password'),
        other: (0, _redux2.getActionError)(action, 'other')
      });
    default:
      return state;
  }
}
var initialLoginFormState = {};
function loginForm() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialLoginFormState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case UPDATE_LOGIN_FORM:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.name, action.value));
    case LOGIN_SUCCESS:
    case GOOGLE_LOGIN_SUCCESS:
      return initialLoginFormState;
    default:
      return state;
  }
}
var initialUserForm = {};
function userForm() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialSignupFormState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case UPDATE_LOGIN_FORM:
    case UPDATE_SIGNUP_FORM:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.name, action.value));
    case LOGIN_SUCCESS:
    case GOOGLE_LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
    case GOOGLE_SIGNUP_SUCCESS:
      return initialUserForm;
    case TOGGLE_TERMS_AGREE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isTermsAgree: !state.isTermsAgree
      });
    default:
      return state;
  }
}
var mainInitialState = exports.mainInitialState = {
  loaded: false,
  loggingOut: false,
  credentialless: false
};
function main() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : mainInitialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case LOAD:
    case LOAD_AUTH_AND_SITE_THEME:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case LOAD_SUCCESS:
    case _accountTypes.FETCH_ACCOUNT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: true,
        user: action.result && (0, _omit.default)(['account_info'], action.result)
      });
    case LOAD_AUTH_AND_SITE_THEME_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: true,
        user: action.result.auth && (0, _omit.default)(['account_info'], action.result.auth),
        siteTheme: action.result.siteTheme
      });
    case LOAD_FAIL:
    case LOAD_AUTH_AND_SITE_THEME_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: false,
        error: (0, _redux2.getActionError)(action, 'other')
      });
    case EXTEND_AUTH:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: true,
        user: _objectSpread(_objectSpread({}, state.user), action.data)
      });
    case LOAD_RESETPASSWORD_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingResetPasswordCode: true,
        resetPasswordCode: action.resetPasswordCode
      });
    case LOAD_RESETPASSWORD_CODE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingResetPasswordCode: false,
        loadedResetPasswordCode: true,
        isResetPasswordCodeValid: action.result
      });
    case LOAD_RESETPASSWORD_CODE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingResetPasswordCode: false,
        loadedResetPasswordCode: false,
        isResetPasswordCodeValid: false
      });
    case LOGIN:
    case GOOGLE_LOGIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingIn: true
      });
    case LOGIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingIn: false,
        user: action.result
      });
    case GOOGLE_LOGIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingIn: false,
        user: action.result.userInfo
      });
    case LOGIN_FAIL:
    case GOOGLE_LOGIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingIn: false,
        user: null
      });
    case SIGNUP:
    case GOOGLE_SIGNUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        signingUp: true
      });
    case SIGNUP_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        signingUp: false,
        user: action.result
      });
    case GOOGLE_SIGNUP_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        signingUp: false,
        user: action.result.userInfo
      });
    case SIGNUP_FAIL:
    case GOOGLE_SIGNUP_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        signingUp: false,
        user: null
      });
    case RESETPASSWORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        resettingPassword: true
      });
    case RESETPASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        resettingPassword: false
      });
    case RESETPASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        resettingPassword: false
      });
    case CHANGELOSTPASSWORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        changingLostPassword: true
      });
    case CHANGELOSTPASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        changingLostPassword: false
      });
    case CHANGELOSTPASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        changingLostPassword: false
      });
    case LOGOUT:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingOut: true
      });
    case LOGOUT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), !__CLIENT__ ? {
        loggingOut: false,
        user: null
      } : {});
    case LOGOUT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingOut: false,
        logoutError: (0, _redux2.getActionError)(action, 'other')
      });
    case LOADED_BY_PLUGIN_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        pluginType: action.pluginType
      });
    case LOADED_BY_REFERER:
      return _objectSpread(_objectSpread({}, state), {}, {
        referer: action.referer
      });
    case LOADED_BY_CREDENTIALLESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        credentialless: action.credentialless
      });
    case SAVE_USER_PREFERENCE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: _objectSpread(_objectSpread({}, state.user), action.userPreference)
      });
    case _helpCenterTypes.SAVE_HELPCENTER_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: _objectSpread(_objectSpread({}, state.user), {}, {
          helpCenterEnabled: action.settings.enabled
        })
      });
    default:
      return state;
  }
}
var _default = exports.default = (0, _redux.combineReducers)({
  main: main,
  changeLostPasswordForm: changeLostPasswordForm,
  changeLostPasswordError: changeLostPasswordError,
  resetPasswordForm: resetPasswordForm,
  resetPasswordError: resetPasswordError,
  loginForm: loginForm,
  loginError: loginError,
  signupForm: signupForm,
  signupError: signupError,
  userForm: userForm
});
function isLoaded(globalState) {
  return globalState.auth && globalState.auth.main && globalState.auth.main.loaded;
}
function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: function promise(client) {
      return client.get('/loadAuth');
    }
  };
}
function isSiteThemeLoaded(globalState) {
  return globalState.auth && globalState.auth.main && globalState.auth.main.siteTheme;
}
function loadAuthAndSiteTheme(params) {
  return {
    types: [LOAD_AUTH_AND_SITE_THEME, LOAD_AUTH_AND_SITE_THEME_SUCCESS, LOAD_AUTH_AND_SITE_THEME_FAIL],
    promise: function promise(client) {
      return client.get('/loadAuthAndSiteTheme', {
        params: params
      });
    }
  };
}
function extendAuth(data) {
  return {
    type: EXTEND_AUTH,
    data: data
  };
}
function resetUserToken() {
  return {
    types: [RESET_USER_TOKEN, RESET_USER_TOKEN_SUCCESS, RESET_USER_TOKEN_FAIL],
    promise: function promise(client) {
      return client.post('/resetUserToken');
    }
  };
}
function loadResetPasswordCode(resetPasswordCode) {
  return {
    types: [LOAD_RESETPASSWORD_CODE, LOAD_RESETPASSWORD_CODE_SUCCESS, LOAD_RESETPASSWORD_CODE_FAIL],
    promise: function promise(client) {
      return client.post('/checkResetPasswordCode', {
        data: {
          resetPasswordCode: resetPasswordCode
        }
      });
    },
    resetPasswordCode: resetPasswordCode
  };
}
function isLoadResetPasswordCodeLoaded(globalState, resetPasswordCode) {
  return globalState.auth && globalState.auth.main && globalState.auth.main.loadedResetPasswordCode && globalState.auth.main.resetPasswordCode === resetPasswordCode;
}
function updateChangeLostPasswordForm(name, value) {
  return {
    type: UPDATE_CHANGELOSTPASSWORD_FORM,
    name: name,
    value: value
  };
}
function validateChangeLostPassword(name, value) {
  return {
    types: [null, UPDATE_CHANGELOSTPASSWORD_ERROR, UPDATE_CHANGELOSTPASSWORD_ERROR],
    promise: function promise(client) {
      return client.post('/changeLostPassword', {
        data: _defineProperty(_defineProperty({}, name, value), "asyncValidateField", name)
      });
    }
  };
}
function changeLostPassword(data) {
  return {
    types: [CHANGELOSTPASSWORD, CHANGELOSTPASSWORD_SUCCESS, CHANGELOSTPASSWORD_FAIL],
    promise: function promise(client) {
      return client.post('/changeLostPassword', {
        data: data
      });
    }
  };
}
function updateResetPasswordForm(name, value) {
  return {
    type: UPDATE_RESETPASSWORD_FORM,
    name: name,
    value: value
  };
}
function clearEmailError(name) {
  return {
    type: CLEAR_EMAIL_ERROR,
    name: name
  };
}
function validateResetPassword(name, value) {
  return {
    types: [null, UPDATE_RESETPASSWORD_ERROR, UPDATE_RESETPASSWORD_ERROR],
    promise: function promise(client) {
      return client.post('/resetPassword', {
        data: _defineProperty(_defineProperty({}, name, value), "asyncValidateField", name)
      });
    }
  };
}
function resetPassword(data) {
  return {
    types: [RESETPASSWORD, RESETPASSWORD_SUCCESS, RESETPASSWORD_FAIL],
    promise: function promise(client) {
      return client.post('/resetPassword', {
        data: data
      });
    }
  };
}
function updateSignupForm(name, value) {
  return {
    type: UPDATE_SIGNUP_FORM,
    name: name,
    value: value
  };
}
function toggleIsTermsAgree() {
  return {
    type: TOGGLE_TERMS_AGREE
  };
}
function clearSignupError(name) {
  return {
    type: CLEAR_SIGNUP_ERROR,
    name: name
  };
}
function validateSignup(name, value) {
  return {
    types: [null, UPDATE_SIGNUP_ERROR, UPDATE_SIGNUP_ERROR],
    promise: function promise(client) {
      return client.post('/signup', {
        data: _defineProperty(_defineProperty({}, name, value), "asyncValidateField", name)
      });
    }
  };
}
function signup(data) {
  return {
    types: [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL],
    promise: function promise(client) {
      return client.post('/signup', {
        data: data
      });
    }
  };
}
function updateLoginForm(name, value) {
  return {
    type: UPDATE_LOGIN_FORM,
    name: name,
    value: value
  };
}
function clearLoginError(name) {
  return {
    type: CLEAR_LOGIN_ERROR,
    name: name
  };
}
function validateLogin(name, value) {
  return {
    types: [null, UPDATE_LOGIN_ERROR, UPDATE_LOGIN_ERROR],
    promise: function promise(client) {
      return client.post('/login', {
        data: _defineProperty(_defineProperty({}, name, value), "asyncValidateField", name)
      });
    }
  };
}
function login(data) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: function promise(client) {
      return client.post('/login', {
        data: data
      });
    }
  };
}
function googleLogin(data) {
  return {
    types: [GOOGLE_LOGIN, GOOGLE_LOGIN_SUCCESS, GOOGLE_LOGIN_FAIL],
    promise: function promise(client) {
      return client.post('/googleAuth', {
        data: data
      });
    }
  };
}
function start2FALogin() {
  return {
    types: [START_2FA_LOGIN, START_2FA_LOGIN_SUCCESS, START_2FA_LOGIN_FAIL],
    promise: function promise(client) {
      return client.post('/user/start2FALogin', {
        data: {}
      });
    }
  };
}
function check2FALogin(data) {
  return {
    types: [CHECK_2FA_LOGIN, CHECK_2FA_LOGIN_SUCCESS, CHECK_2FA_LOGIN_FAIL],
    promise: function promise(client) {
      return client.post('/user/check2FALogin', {
        data: data
      });
    }
  };
}
function googleSignup(data) {
  return {
    types: [GOOGLE_SIGNUP, GOOGLE_SIGNUP_SUCCESS, GOOGLE_SIGNUP_FAIL],
    promise: function promise(client) {
      return client.post('/googleAuth', {
        data: data
      });
    }
  };
}
function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: function promise(client) {
      return client.get('/logout');
    }
  };
}
function loadedByPluginType(pluginType) {
  return {
    type: LOADED_BY_PLUGIN_TYPE,
    pluginType: pluginType
  };
}
function loadedByReferer(referer) {
  return {
    type: LOADED_BY_REFERER,
    referer: referer
  };
}
function loadedByCredentialless(credentialless) {
  return {
    type: LOADED_BY_CREDENTIALLESS,
    credentialless: credentialless
  };
}
function resendActivation(data) {
  return {
    types: [RESEND_ACTIVATION, RESEND_ACTIVATION_SUCCESS, RESEND_ACTIVATION_FAIL],
    promise: function promise(client) {
      return client.post('/resendActivation', {
        data: data
      });
    }
  };
}
var saveUserPreference = exports.saveUserPreference = function saveUserPreference(userPreference, otherData) {
  return _objectSpread({
    types: [SAVE_USER_PREFERENCE, SAVE_USER_PREFERENCE_SUCCESS, SAVE_USER_PREFERENCE_FAIL],
    promise: function promise(client) {
      return client.post('/user/saveUserPreference', {
        data: _objectSpread({
          userPreference: userPreference
        }, otherData)
      });
    },
    userPreference: userPreference
  }, otherData);
};
var getLocalState = function getLocalState(state) {
  return state.auth;
};
var getUser = exports.getUser = function getUser(state) {
  return getLocalState(state).main.user;
};
function loginWithGracehill(jwtToken) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: function promise(client) {
      return client.post('/loginGracehill', {
        data: {
          jwtToken: jwtToken
        }
      });
    }
  };
}