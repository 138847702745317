"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extensionTutorialLanguage = exports.extensionIntegrationCaptureMode = exports.extensionDefaultView = exports.extensionDefaultTab = void 0;
exports.getExtensionDefaultTab = getExtensionDefaultTab;
var _languages = _interopRequireDefault(require("utils/languages"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var extensionTutorialLanguage = exports.extensionTutorialLanguage = _languages.default.map(function (l) {
  return {
    key: l.code,
    text: l.name
  };
}).sort(function (a, b) {
  if (a.key === 'en') {
    return -1;
  }
  if (b.key === 'en') {
    return 1;
  }
  return 0;
});
var extensionIntegrationCaptureMode = exports.extensionIntegrationCaptureMode = [{
  key: 'choose',
  text: 'Web/Desktop'
}, {
  key: 'web',
  text: 'Web'
}, {
  key: 'desktop',
  text: 'Desktop'
}];
var extensionDefaultView = exports.extensionDefaultView = [{
  key: 'categories',
  text: 'Only Categories'
}, {
  key: 'categories,subcategories',
  text: 'Categories/Subcategories'
}, {
  key: 'all',
  text: 'Everything expanded'
}];
var extensionDefaultTab = exports.extensionDefaultTab = [{
  key: 'creator',
  text: 'Capture'
}, {
  key: 'categories',
  text: 'Library'
}, {
  key: 'qa',
  text: 'The Square'
}];
function getExtensionDefaultTab() {
  var disableTheSquare = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var disableCapture = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return extensionDefaultTab.filter(function (tab) {
    if (disableTheSquare && tab.key === 'qa') {
      return false;
    }
    return !(disableCapture && tab.key === 'creator');
  });
}