"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MAX_FILE_SIZE_MB = void 0;
exports.applyComponentNamesToText = applyComponentNamesToText;
exports.applyUpdateJsonData = applyUpdateJsonData;
exports.escapeTextForSSML = escapeTextForSSML;
exports.filterLiveUrlConfigTree = filterLiveUrlConfigTree;
exports.mergeLiveUrlConfigTree = mergeLiveUrlConfigTree;
exports.stripTags = stripTags;
var _merge = _interopRequireDefault(require("lodash/merge"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _set = _interopRequireDefault(require("lodash/set"));
var _get = _interopRequireDefault(require("lodash/get"));
var _remove = _interopRequireDefault(require("lodash/remove"));
var _uniq = _interopRequireDefault(require("lodash/uniq"));
var _escapeRegExp = _interopRequireDefault(require("lodash/escapeRegExp"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var MAX_FILE_SIZE_MB = exports.MAX_FILE_SIZE_MB = 150;
function fixNodeForSSML(node) {
  if (!node || !node.childNodes) return;
  for (var i = 0; i < node.childNodes.length; i++) {
    var child = node.childNodes[i];
    if (child.nodeType === Node.TEXT_NODE) {
      // quotes are problematic, thus just remove them
      child.textContent = child.textContent.replace(/"/g, '').replace(/'/g, '');
    } else {
      fixNodeForSSML(child);
    }
  }
}

// NOTE: html contains actual HTML tags (e.g. <b>) mixed with user-inserted text (e.g. &lt;speak&gt; for SSML)
// NOTE: html is assumed to be XSS safe
function stripTags(html) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var doc = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : document;
  // Replace BR elements with newlines, otherwise
  // they be converted to ''
  // and will cause issues when generating audio/video
  // i.e. reading 'Step.<br>Then' as "Step dot Then"
  // instead that just "Step Then"
  // See https://github.com/iorad/iorad/issues/8150
  html = html.replace(/<br>|<br \/>|<br\/>/, '\n');

  // Convert non-ascii quotes (typically obtained from Microsoft Word, etc.)
  // to normal ascii quotes to prevent SSML errors
  html = html.replace(/”|“/g, '"');
  var parser = doc.createElement('DIV');
  parser.innerHTML = html;

  // removes actual HTML tags (e.g. <b>)
  // and decodes user-inserted text (e.g. &lt;speak&gt; for SSML is converted into actual HTML <speak>)
  var text = parser.textContent || parser.innerText || '';
  if (options.asHTML) {
    // See https://console.bluemix.net/docs/services/text-to-speech/http.html#escape
    fixNodeForSSML(parser);
    text = text.replace(/&/g, '&amp;');
  }
  return text;
}

// NOTE: assumes text was obtained from stripTags(html, { asHTML: false })
// See https://docs.aws.amazon.com/polly/latest/dg/escapees.html
function escapeTextForSSML(text) {
  var replacements = {
    '&': '&amp;',
    "'": '&apos;',
    '<': '&lt;',
    '>': '&gt;'
  };
  return text.replace(/["&'<>]/g, function (match) {
    return replacements[match] || match;
  });
}
function applyUpdateJsonData(oldJson, jsonData) {
  if (!Array.isArray(jsonData)) {
    jsonData = [{
      type: 'merge',
      data: jsonData
    }];
  }
  var json = (0, _cloneDeep.default)(oldJson);
  jsonData.forEach(function (action) {
    if (action.type === 'merge') {
      (0, _merge.default)(json, action.data);
    } else if (action.type === 'set') {
      action.data.forEach(function (obj) {
        (0, _set.default)(json, obj.path, obj.value);
      });
    } else if (action.type === 'unset') {
      action.data.forEach(function (jsonPath) {
        var pathParts = jsonPath.split('.');
        var parentPath = pathParts.slice(0, -1).join('.');
        var parent = parentPath ? (0, _get.default)(json, parentPath) : json;
        var parentProp = pathParts[pathParts.length - 1];
        if (typeof parent !== 'undefined' && parent !== null) {
          delete parent[parentProp];
        }
      });
    } else if (action.type === 'splice') {
      action.data.forEach(function (obj) {
        var array = (0, _get.default)(json, obj.path);
        array.splice.apply(array, _toConsumableArray(obj.args));
      });
    }
  });
  return json;
}
function mergeLiveUrlConfigTree() {
  var baseTopTreeList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var otherTopTreeList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var baseTreeList = baseTopTreeList;
  var otherTreeList = otherTopTreeList;
  var _loop = function _loop() {
    var otherTreeItem = otherTreeList[i];
    if (otherTreeItem.exact) {
      var exactBaseTreeItem = baseTreeList.find(function (aBaseTreeItem) {
        return aBaseTreeItem.key === otherTreeItem.key && aBaseTreeItem.exact && aBaseTreeItem.value === otherTreeItem.value;
      });
      if (exactBaseTreeItem) {
        exactBaseTreeItem.list = mergeLiveUrlConfigTree(exactBaseTreeItem.list, otherTreeItem.list);
      } else {
        var wildcardBaseTreeItem = baseTreeList.find(function (aBaseTreeItem) {
          return aBaseTreeItem.key === otherTreeItem.key && !aBaseTreeItem.exact && aBaseTreeItem.value.includes(otherTreeItem.value);
        });
        if (wildcardBaseTreeItem) {
          (0, _remove.default)(wildcardBaseTreeItem.value, otherTreeItem.value);
          if (wildcardBaseTreeItem.value.length === 0) {
            (0, _remove.default)(baseTreeList, wildcardBaseTreeItem);
          }
          baseTreeList.push(_objectSpread(_objectSpread({}, otherTreeItem), {}, {
            list: mergeLiveUrlConfigTree(wildcardBaseTreeItem.list, otherTreeItem.list)
          }));
        } else {
          baseTreeList.push(_objectSpread({}, otherTreeItem));
        }
      }
    } else {
      // non exact
      // NOTE: keep ordering in value array (for recursion)
      var exactBaseTreeItems = otherTreeItem.value.map(function (aValue) {
        return baseTreeList.find(function (aBaseTreeItem) {
          return aBaseTreeItem.key === otherTreeItem.key && aBaseTreeItem.exact && aBaseTreeItem.value === aValue;
        });
      }).filter(function (x) {
        return x;
      });
      (0, _remove.default)(baseTreeList, function (aBaseTreeItem) {
        return exactBaseTreeItems.includes(aBaseTreeItem);
      });
      var _wildcardBaseTreeItem = baseTreeList.find(function (aBaseTreeItem) {
        return aBaseTreeItem.key === otherTreeItem.key && !aBaseTreeItem.exact;
      });
      if (_wildcardBaseTreeItem) {
        var newValue = _wildcardBaseTreeItem.value;
        var _iterator = _createForOfIteratorHelper(otherTreeItem.value),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var otherValue = _step.value;
            if (!_wildcardBaseTreeItem.value.includes(otherValue)) {
              newValue.push(otherValue);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        _wildcardBaseTreeItem.value = newValue;
        _wildcardBaseTreeItem.list = [_wildcardBaseTreeItem.list].concat(exactBaseTreeItems.map(function (exactBaseTreeItem) {
          return exactBaseTreeItem.list;
        })).concat([otherTreeItem.list]).reduce(function (totalList, currentList) {
          return mergeLiveUrlConfigTree(totalList, currentList);
        }, []);
      } else {
        baseTreeList.push(_objectSpread(_objectSpread({}, otherTreeItem), {}, {
          list: exactBaseTreeItems.map(function (exactBaseTreeItem) {
            return exactBaseTreeItem.list;
          }).concat([otherTreeItem.list]).reduce(function (totalList, currentList) {
            return mergeLiveUrlConfigTree(totalList, currentList);
          }, [])
        }));
      }
    }
  };
  for (var i = 0; i < otherTreeList.length; i++) {
    _loop();
  }
  return baseTopTreeList;
}

// keep hostnames and all their subdomains
function filterLiveUrlConfigTree() {
  var mergedList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var hostnamePartsList = arguments.length > 1 ? arguments[1] : undefined;
  var currentHostnameIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var filteredList = [];
  var _loop2 = function _loop2() {
    var mergedItem = mergedList[i];
    if (mergedItem.key !== "hostname.".concat(currentHostnameIndex)) {
      // end of recursion: we're done with this item and all its tree
      filteredList.push(mergedItem);
    } else {
      var currentHostnameIndexPartsList = hostnamePartsList.filter(function (hostnameParts) {
        return hostnameParts[currentHostnameIndex];
      });
      if (currentHostnameIndexPartsList.length === 0) {
        // end of recursion: subdomain
        filteredList.push(mergedItem);
      } else {
        if (!mergedItem.exact) {
          // all that aren't exactly matched
          currentHostnameIndexPartsList = currentHostnameIndexPartsList.filter(function (hostnameParts) {
            return !mergedList.some(function (aMergedItem) {
              return aMergedItem.key === "hostname.".concat(currentHostnameIndex) && aMergedItem.exact && aMergedItem.value === hostnameParts[currentHostnameIndex];
            });
          });
        } else {
          // exact matches
          currentHostnameIndexPartsList = currentHostnameIndexPartsList.filter(function (hostnameParts) {
            return hostnameParts[currentHostnameIndex] === mergedItem.value;
          });
        }
        if (currentHostnameIndexPartsList.length !== 0) {
          filteredList.push(_objectSpread(_objectSpread({}, mergedItem), {}, {
            list: filterLiveUrlConfigTree(mergedItem.list, currentHostnameIndexPartsList, currentHostnameIndex + 1)
          }));
        }
      }
    }
  };
  for (var i = 0; i < mergedList.length; i++) {
    _loop2();
  }
  return filteredList;
}

// description is HTML, having component names specified in <b> tags
// we want to extract all component names from the description
// and apply them to the plain text
// NOTE: order by longest first so that we don't replace substrings
// NOTE: actually text can also be (partially) HTML, as the code naturally handles it
function applyComponentNamesToText(description, text) {
  var componentNames = (0, _uniq.default)(Array.from(description.matchAll(/<b>(.+?)<\/b>/g)).map(function (match) {
    return match[1];
  })).sort(function (a, b) {
    return b.length - a.length;
  }); // sort by length DESC
  componentNames.forEach(function (componentName) {
    // NOTE: handle components that are inside other components
    // e.g. componentNames = ['Component 1', 'Component']
    // with text = 'Click on Component 1 and then on Component'
    // => replaced text should be 'Click on <b>Component 1</b> and then on <b>Component</b>'
    // => not 'Click on <b>Component</b> 1 and then on <b>Component</b>'
    // => neither 'Click on <b><b>Component</b> 1</b> and then on <b>Component</b>'
    var newText = '';
    var remainingText = text;
    while (remainingText) {
      var match = remainingText.match(new RegExp("['\"]?\\b".concat((0, _escapeRegExp.default)(componentName), "\\b['\"]?"), 'i'));
      if (!match) {
        break;
      }
      if (typeof match.index === 'undefined') {
        break;
      }
      var matchBefore = remainingText.slice(0, match.index);
      var matchAfter = remainingText.slice(match.index + match[0].length);
      var lastPrevComponentOpenerIndex = (newText + matchBefore).lastIndexOf('<b>');
      var lastPrevComponentCloserIndex = (newText + matchBefore).lastIndexOf('</b>');
      if (lastPrevComponentOpenerIndex === -1 || lastPrevComponentCloserIndex > lastPrevComponentOpenerIndex) {
        // match is not in another component (i.e. wasn't already replaced)
        // => we can replace it
        newText += matchBefore + "<b>".concat(componentName, "</b>");
      } else {
        newText += matchBefore + componentName;
      }
      remainingText = matchAfter;
    }
    newText += remainingText;
    text = newText;
  });
  return text;
}